body {
  font-family: Courier, "Courier New", monospace;
  font-size: 14px;
  line-height: 1.2;
  margin: 0px;
  padding: 0px;
  position: relative;
  width: 100%;
  overflow-x: hidden;
  min-height: -webkit-fill-available;
}
html {
  width: 100%;
  height: -webkit-fill-available;
}
p {
  text-align: justify;
  color: #c8c8c8;
  padding: 0px;
  margin: 0px;
  position: absolute;
}

p.left {
  left: 0px;
  top: 0px;
  max-width: 810px;
  width: 70%;
}

p.right {
  right: 0px;
  top: 0px;
  width: 210px;
  text-align: right;
}
p.right .social {
  display: flex;
  justify-content: space-between;
}
p.right .social img {
  height: 28px;
  width: auto;
}

.main {
  position: relative;
  width: calc(100vw - 60px);
  height: calc(100vh - 60px);
  max-height: -webkit-fill-available !important;
  left: 30px;
  top: 30px;
}

.background {
  width: 100vw;
  height: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  max-height: -webkit-fill-available !important;
}

.fullScreen {
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available !important;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, 0);
  object-fit: cover;
}

nav {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 30px;
  text-transform: uppercase;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  left: 0px;
  bottom: 80px;
}

nav .inner {
  width: 880px;
  justify-content: space-between;
  display: flex;
  margin: 0 auto;
}
nav .inner a {
  color: #fff;
  text-decoration: none;
}
nav .inner a:hover {
  background: #ffffff;
  opacity: 1;
}

.videoNav {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 30px;
  text-transform: uppercase;

  position: absolute;
  left: 0px;
  bottom: 15px;
}
.videoNav .inner {
  width: 65px;
  justify-content: space-between;
  display: flex;
  margin: 0 auto;
}

.videoNav .inner div {
  border-radius: 50px;
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  box-sizing: border-box;
  cursor: url(./cursor.png) 30 30, move;
}
.videoNav .inner div.active {
  background: #fff;
}
.videoNav .inner div:hover {
  opacity: 0.5;
}
a:hover {
  cursor: url(./cursor.png) 30 30, move;
  opacity: 0.5;
}

.main.white {
  background: white;
}

.covers {
  width: 100vw;
  margin-left: -30px;
  height: calc(100vh - 60px);
  margin-top: -30px;
  background: url(./okladki.jpg) repeat-x left top;
  background-size: auto 100%;
  transform: translate3d(0, 0, 0);
  transition: background-position cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.arrowBack {
  width: 22px;
  height: 15px;
  background: url(./back_b.jpg) no-repeat center center;
  background-size: contain;
  display: block;
  position: absolute;
  left: 10%;
  bottom: 20px;
}

.arrowBack.white {
  background: url(./back_w.png) no-repeat center center;
  background-size: contain;
  bottom: 10px;
}

.coversContainer .arrowBack {
  bottom: -20px !important;
}

a.disabled {
  cursor: not-allowed;
}

.contact {
  width: 550px;
  color: #6f6f6f;
  font-size: 18px;
  background: url(./contact.png) no-repeat center top;
  background-size: 100% auto;
  padding: 250px 100px 0px 100px;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.contact a {
  color: #6f6f6f;
  text-decoration: underline;
}
.mobile {
  display: none;
}

.arrowBack {
  width: 22px;
  height: 15px;
  background: url(./back_b.jpg) no-repeat center center;
  background-size: contain;
  display: block;
  position: absolute;
  left: 10%;
  bottom: 20px;
}

.playPause {
  width: 22px;
  height: 22px;
  background: url(./playPause.png) no-repeat top center;
  background-size: 100% auto;
  display: block;
  position: absolute;
  right: 10%;
  bottom: 20px;
}

.playPause.pause {
  background-position: center bottom;
}

@media (max-width: 1024px) {
  html,
  body {
    overflow-y: hidden !important;

    overscroll-behavior-y: none;
    height: calc(100vh - 150px);
    height: -webkit-fill-available;
    max-height: -webkit-fill-available;
    background: #fcfafc;
  }
  .burger {
    display: block;

    margin-top: 12px;
    width: 90px;
    height: 30px;
    background: url(./mobile/burger.png) no-repeat center center;
    z-index: 3;
    background-size: contain;
    margin-left: -4px;
  }
  .burger.active {
    background: url(./mobile/burger-active.png) no-repeat center center;
    background-size: contain;
  }
  .main {
    position: relative;
    width: calc(100vw - 40px);
    height: calc(100vh - 150px);
    max-height: -webkit-fill-available;
    left: 20px;
    top: 30px;
  }
  .close {
    display: block;
    position: absolute;
    width: 22px;
    top: 14px;
    right: 18px;
    height: 22px;
    background: url(./mobile/burger2.png) no-repeat center center;
    background-size: cover;
    z-index: 100;
  }
  .covers {
    width: 100vw;
    margin-left: 0;
    height: calc(100vh - 160px);
    margin-top: -30px;
    background: url(./okladki.jpg) repeat-x left top;
    background-size: auto 100%;
    transform: translate3d(0, 0, 0);
    transition: background-position cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .coversContainer {
    width: 100vw;
    left: 0;
  }

  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .fullScreenHome {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: calc(100vh - 150px);
    background: #fcfafc;
  }
  .fullScreenHome img {
    width: 100vw;
    height: auto;
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    opacity: 0;
    margin-top: -20px;
  }
  .fullScreenHome img.active {
    opacity: 1;
  }
  p.left {
    z-index: 3;
    margin-top: -10px;
    width: 100%;
    font-size: 12px;
    color: #eeecec;
  }

  p.left .bg {
    display: inline;
    background: rgba(91, 157, 182, 0.33);

    border-radius: 0.5;
  }
  p.right {
    display: none;
    background: #000;
    right: 0;
    top: 0;
    width: 100vw;
    padding: 20px;
    height: 50px;
    z-index: 4;
    position: fixed;
    margin: 0;
    box-sizing: border-box;
  }
  p.right.active {
    display: block;
  }
  p.right .social {
    position: absolute;

    box-sizing: border-box;
    top: 15px;
    width: 160px;
  }
  p.right .social img {
    height: 20px;
  }
  p.right br + br {
    display: none;
  }
  nav {
    font-size: 20px;
    line-height: 27px;
    width: 320px;
    left: 50%;
    display: block;
    transform: translateX(-50%);
    text-align: center;
    bottom: 40px;
  }
  nav .inner {
    width: 100%;
    display: block;
    text-align: center;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  nav .inner a {
    color: #000;
    display: inline-block;
    font-size: 22px;
    padding-right: 25px;
  }
  nav .inner a:nth-of-type(3) {
    padding-right: 0px;
  }
  nav .inner a:last-of-type {
    padding-right: 0px;
  }
  nav .inner a:hover {
    background: #000;
  }
  .contact {
    width: 100%;
    padding: 150px 20px 0;
  }
  .playPause {
    bottom: 10px;
  }
  .break {
    flex-basis: 100%;
    height: 0;
  }
  .fullScreen {
    background: #000;
  }
}
